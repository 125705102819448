import AppRoutes from "./Routes";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

function App({ signOut, user }) {
  console.log("user data: ", user);
  return (
    <BrowserRouter>
      <AppRoutes signOut={signOut} user={user?.attributes} />
    </BrowserRouter>
  );
}

export default withAuthenticator(App);
