export const serviceTab = [
  {
    text: "Cash Collection",
    route: "/cash-collection",
  },
  {
    text: "Manual Swap",
    route: "/manual-swap",
  },
  // {
  //   text: "Bypass",
  //   route: "/bypass",
  // },
];
