import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StepFive, StepFour, StepOne } from "./steps";
import axios from "axios";
import { PageTitle } from "../utils";

const steps = [
  {
    label: "Select Station IMEI",
    component: "StepOne",
    apiUrl:
      "https://iru5fzzng1.execute-api.us-east-1.amazonaws.com/dev/api/general/getDeviceIds",
  },
  {
    label: "Select Partner ID",
    component: "StepTwo",
    apiUrl:
      "https://iru5fzzng1.execute-api.us-east-1.amazonaws.com/dev/api/general/getListOfPartnerIds",
  },
  {
    label: "Select Driver ID",
    component: "StepThree",
    apiUrl:
      "https://vmh8bgwlcd.execute-api.us-east-1.amazonaws.com/dev/api/drivers/getListOfDriverIds",
  },
  {
    label: "Select Batteries ID",
    component: "StepFour",
    apiUrl:
      "https://gjkanddrxd.execute-api.us-east-1.amazonaws.com/dev/api/batteries/getListOfBatteries",
  },
  {
    label: "Add Manual Swap",
    component: "StepFive",
    apiUrl: "null",
  },
];

const ManualSwap = () => {
  const [selectedStationId, setSelectedStationId] = useState(null);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [selectedInsertBatteryId, setSelectedInsertBatteryId] = useState(null);
  const [selectedTakenBatteryId, setSelectedTakenBatteryId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [resData, setResData] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const formObj = {
    deviceId: "",
    partnerId: "",
    driverId: "",
    insertedBatteryId: "",
    takenBatteryId: "",
    insertedBatterySoc: "",
    takenBatterySoc: "",
  };
  const [formData, setFormData] = useState(formObj);

  const handleNext = () => {
    if (activeStep < 6) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setSelectedStationId(null);
    setSelectedPartnerId(null);
    setSelectedDriverId(null);
    setSelectedInsertBatteryId(null);
    setSelectedTakenBatteryId(null);
    setFormData(formObj);
    setIsSubmit(false);
    setErrorMsg("");
  };

  useEffect(() => {
    if (selectedStationId)
      setFormData((prev) => ({ ...prev, deviceId: selectedStationId.value }));
    if (selectedPartnerId)
      setFormData((prev) => ({ ...prev, partnerId: selectedPartnerId.value }));
    if (selectedDriverId)
      setFormData((prev) => ({ ...prev, driverId: selectedDriverId.value }));
    if (selectedInsertBatteryId)
      setFormData((prev) => ({
        ...prev,
        insertedBatteryId: selectedInsertBatteryId.value,
      }));
    if (selectedTakenBatteryId)
      setFormData((prev) => ({
        ...prev,
        takenBatteryId: selectedTakenBatteryId.value,
      }));
  }, [
    selectedStationId,
    selectedPartnerId,
    selectedDriverId,
    selectedInsertBatteryId,
    selectedTakenBatteryId,
  ]);

  const handleSubmitManualSwap = () => {
    setIsSubmit(true);
    let config = {
      method: "post",
      url: "https://rb3sq56z0d.execute-api.us-east-1.amazonaws.com/dev/swap/addManualSwap",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(formData),
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResData(response.data.message);
        setIsSubmit(false);
      })
      .catch((error) => {
        console.log(error);
        setErrorMsg(error.message);
        setIsSubmit(false);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  console.log("formData: ", formData);

  return (
    <Box sx={{ py: 5 }}>
      <Box
        sx={{
          width: { xs: "90%", sm: "70%", md: "50%" },
          m: "auto",
          textAlign: "left",
          border: "1px solid gray",
          boxSizing: "border-box",
          p: { xs: 1, sm: 2, md: 5 },
          position: "relative",
        }}
      >
        <PageTitle title="Manual Swap" />
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label} expanded={activeStep >= index}>
              <StepLabel
                optional={
                  index === 4 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                {step.component === "StepOne" ? (
                  <StepOne
                    selectedItem={selectedStationId}
                    onChange={setSelectedStationId}
                    isActive={selectedStationId}
                    onClickNext={handleNext}
                    onClickBack={handleBack}
                    index={index}
                    activeStep={activeStep}
                    apiUrl={step.apiUrl}
                  />
                ) : null}
                {step.component === "StepTwo" ? (
                  <StepOne
                    selectedItem={selectedPartnerId}
                    onChange={setSelectedPartnerId}
                    isActive={selectedPartnerId}
                    onClickNext={handleNext}
                    onClickBack={handleBack}
                    index={index}
                    activeStep={activeStep}
                    apiUrl={step.apiUrl}
                  />
                ) : null}
                {step.component === "StepThree" ? (
                  <StepOne
                    selectedItem={selectedDriverId}
                    onChange={setSelectedDriverId}
                    isActive={selectedDriverId}
                    onClickNext={handleNext}
                    onClickBack={handleBack}
                    index={index}
                    activeStep={activeStep}
                    apiUrl={step.apiUrl}
                  />
                ) : null}
                {step.component === "StepFour" ? (
                  <StepFour
                    selectedItem1={selectedInsertBatteryId}
                    selectedItem2={selectedTakenBatteryId}
                    onChange1={setSelectedInsertBatteryId}
                    onChange2={setSelectedTakenBatteryId}
                    isActive={selectedInsertBatteryId && selectedTakenBatteryId}
                    onClickNext={handleNext}
                    onClickBack={handleBack}
                    index={index}
                    activeStep={activeStep}
                    apiUrl={step.apiUrl}
                  />
                ) : null}

                {step.component === "StepFive" ? (
                  <StepFive
                    formData={formData}
                    onClickNext={handleNext}
                    onClickBack={handleBack}
                    index={index}
                    isActive={resData || false}
                    activeStep={activeStep}
                    handleReset={handleReset}
                    isSubmit={isSubmit}
                    onSubmit={handleSubmitManualSwap}
                    onChange={handleChange}
                    resData={resData}
                    errorMsg={errorMsg}
                  />
                ) : null}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default ManualSwap;
