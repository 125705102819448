import { Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import ActionButtons from "../buttons/ActionButtons";

const StepOne = ({
  selectedItem,
  name,
  onChange,
  isActive,
  onClickNext,
  onClickBack,
  index,
  activeStep,
  apiUrl,
}) => {
  const [options, setOprions] = useState([]);
  useEffect(() => {
    getListOfPartnerIds();
  }, [apiUrl]);

  const getListOfPartnerIds = () => {
    axios
      .request({
        method: "get",
        url: apiUrl,
      })
      .then((response) => {
        const res = response.data.data.map((item) => {
          return {
            value: item,
            label: item,
          };
        });
        setOprions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={{ maxWidth: 300 }}>
      <Select
        isClearable
        options={options}
        value={selectedItem}
        name={name}
        onChange={onChange}
        isDisabled={activeStep > index}
      />

      <ActionButtons
        isActive={activeStep > index || !isActive}
        onClickNext={onClickNext}
        onClickBack={onClickBack}
        index={index}
        activeStep={activeStep}
      />
    </Box>
  );
};

export default StepOne;
