export const dateFormatUtil = (seconds, nanoseconds) => {
  if (seconds) {
    const milliseconds = seconds * 1000;
    const date = new Date(milliseconds + nanoseconds / 1000000);

    const formattedDate = date.toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedDate;
  }
  return "-";
};
