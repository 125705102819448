import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import ActionButtons from "../buttons/ActionButtons";

const StepFour = ({
  selectedItem1,
  selectedItem2,
  onChange1,
  onChange2,
  name,
  isActive,
  onClickNext,
  onClickBack,
  index,
  activeStep,
  apiUrl,
}) => {
  const [options, setOprions] = useState([]);
  useEffect(() => {
    getListOfPartnerIds();
  }, [apiUrl]);

  const getListOfPartnerIds = () => {
    axios
      .request({
        method: "get",
        url: apiUrl,
      })
      .then((response) => {
        const res = response.data.data.map((item) => {
          return {
            value: item,
            label: item,
          };
        });
        setOprions(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={{ maxWidth: 300 }}>
      <Box>
        <Typography>Inserted BatteryId</Typography>
        <Select
          isClearable
          options={options}
          value={selectedItem1}
          name={name}
          onChange={onChange1}
          isDisabled={activeStep > index}
        />
        <Typography sx={{ marginTop: "15px" }}>Taken BatteryId</Typography>
        <Select
          isClearable
          options={options}
          value={selectedItem2}
          name={name}
          onChange={onChange2}
          isDisabled={activeStep > index}
          styles={{ marginTop: "10px" }}
        />
      </Box>

      {selectedItem1 &&
      selectedItem2 &&
      selectedItem1?.value === selectedItem2?.value ? (
        <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
          Both battery ids should be different.
        </Typography>
      ) : null}

      <ActionButtons
        isActive={activeStep > index || !isActive}
        onClickNext={onClickNext}
        onClickBack={onClickBack}
        index={index}
        activeStep={activeStep}
      />
    </Box>
  );
};

export default StepFour;
