import { Route, Routes } from "react-router-dom";
import { CashCollection, HomePage, ManualSwap, PageNotFound } from "./layout";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = ({ signOut, user }) => {
  return (
    <Routes>
      <Route path="/" element={<HomePage signOut={signOut} user={user} />} />
      <Route
        path="/cash-collection"
        element={
          <ProtectedRoute user={user} feature="cash">
            <CashCollection />
          </ProtectedRoute>
        }
      />
      <Route
        path="/manual-swap"
        element={
          <ProtectedRoute user={user} feature="manual">
            <ManualSwap />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
