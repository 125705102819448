import { Box, Button, Typography } from "@mui/material";
import Services from "./services/Services";

const HomePage = ({ signOut, user }) => {
  return (
    <Box sx={{ width: { xs: "90%", sm: "70%", md: "50%" }, m: "auto" }}>
      <Box sx={{ my: 8, textAlign: "center" }}>
        <img src="logo.png" alt="logo" />
      </Box>
      <Services />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 8,
          alignItems: "center",
          gap: 2,
          flexDirection: { xs: "column", sm: "row", md: "row" },
        }}
      >
        <Typography variant="body1">{user.email}</Typography>
        <Button
          onClick={signOut}
          sx={{
            border: "1px solid #1976d2",
          }}
        >
          Sign out
        </Button>
      </Box>
    </Box>
  );
};

export default HomePage;
