import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { dateFormatUtil } from "../../utils";
import ActionButtons from "../buttons/ActionButtons";

const StepTwo = ({
  partnerId,
  onClickNext,
  onClickBack,
  index,
  isLoading,
  activeStep,
}) => {
  const commonStyle = {
    display: "flex",
    p: 1,
    borderBottom: "1px solid gray",
  };
  const [partnerDetails, setPartnerDetails] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    partnerId && getPartnerDetails();
    // eslint-disable-next-line
  }, [partnerId]);

  const getPartnerDetails = () => {
    setLoading(true);
    axios
      .request({
        method: "get",
        url: `https://iru5fzzng1.execute-api.us-east-1.amazonaws.com/dev/api/partners/${partnerId}`,
      })
      .then((response) => {
        setPartnerDetails(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={{ maxWidth: 500, minHeight: 200 }}>
      {!loading && partnerDetails ? (
        <Box>
          <Typography variant="h4" sx={{ mb: 5, textAlign: "center" }}>
            {partnerDetails.partnerId}
          </Typography>
          <Box sx={commonStyle}>
            <Typography flex={2}>Partner Name</Typography>
            <Typography flex={3}>{partnerDetails.name}</Typography>
          </Box>
          <Box sx={commonStyle}>
            <Typography flex={2}>Phone Number</Typography>
            <Typography flex={3}>{partnerDetails.phoneNumber}</Typography>
          </Box>
          <Box sx={commonStyle}>
            <Typography flex={2}>Wallet Balance</Typography>
            <Typography flex={3}>{partnerDetails.walletBalance}</Typography>
          </Box>
          <Box sx={commonStyle}>
            <Typography flex={2}>Last Bill Paid Date</Typography>
            <Typography flex={3}>
              {dateFormatUtil(
                partnerDetails?.lastbillPaidDate?._seconds,
                partnerDetails?.lastbillPaidDate?._nanoseconds
              )}
            </Typography>
          </Box>
          <ActionButtons
            isActive={activeStep > index}
            onClickNext={onClickNext}
            onClickBack={onClickBack}
            index={index}
            isLoading={isLoading}
            activeStep={activeStep}
          />
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default StepTwo;
