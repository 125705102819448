import { Box, Typography } from "@mui/material";
import { serviceTab } from "./constant";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "left",
        display: "grid",
        gridTemplateColumns: {
          xs: `repeat(1, 100%)`,
          sm: `repeat(2, 45%)`,
          md: `repeat(2, 45%)`,
        },
        justifyContent: "space-around",
        gap: 5,
        mt: "12%",
      }}
    >
      {serviceTab.map((item) => (
        <Box
          key={item.route}
          sx={{
            bgcolor: "#1DA7A7",
            color: "#FFFFFF",
            p: "10px",
            py: "40px",
            textAlign: "center",
            cursor: "pointer",
            "&:hover": {
              bgcolor: "#0F0F0F",
              color: "#FFFFFF",
            },
          }}
          onClick={() => handleNavigate(item.route)}
        >
          <Typography variant="h4" sx={{ p: 0, m: 0 }}>
            {item.text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Services;
