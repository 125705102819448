import { Box, TextField, Typography } from "@mui/material";
import ActionButtons from "../buttons/ActionButtons";

const inputStyle = { display: "flex", flexDirection: "column", gap: "4px" };

const StepFive = ({
  formData,
  onChange,
  onSubmit,
  onClickBack,
  index,
  handleReset,
  isSubmit,
  errorMsg,
  resData,
}) => {
  const isValidInsertedBatterySoc =
    /^\d+$/.test(formData.insertedBatterySoc) &&
    parseInt(formData.insertedBatterySoc, 10) >= 0;

  const isValidTakenBatterySoc =
    /^\d+$/.test(formData.takenBatterySoc) &&
    parseInt(formData.takenBatterySoc, 10) >= 0;

  const isActive =
    !formData.deviceId ||
    !formData.partnerId ||
    !formData.driverId ||
    !formData.insertedBatteryId ||
    !formData.takenBatteryId ||
    !isValidInsertedBatterySoc ||
    !isValidTakenBatterySoc;
  return (
    <Box
      sx={{
        maxWidth: 500,
        "&.MuiFormControl-root": {
          "&.MuiOutlinedInput-root": {
            maxWidth: "40px",
          },
        },
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <div style={inputStyle}>
            <label>Station IMEI</label>
            <TextField size="small" disabled value={formData.deviceId} />
          </div>
          <div style={inputStyle}>
            <label>Partner ID</label>
            <TextField size="small" disabled value={formData.partnerId} />
          </div>
          <div style={inputStyle}>
            <label>Driver ID</label>
            <TextField size="small" disabled value={formData.driverId} />
          </div>
          <div style={inputStyle}>
            <label>Inserted Battery ID</label>
            <TextField
              size="small"
              disabled
              value={formData.insertedBatteryId}
            />
          </div>
          <div style={inputStyle}>
            <label>Taken Battery ID</label>
            <TextField size="small" disabled value={formData.takenBatteryId} />
          </div>
          <div style={inputStyle}>
            <label>Inserted Battery SOC</label>
            <TextField
              size="small"
              type="number"
              name="insertedBatterySoc"
              onChange={onChange}
            />
          </div>
          <div style={inputStyle}>
            <label>Taken Battery SOC</label>
            <TextField
              size="small"
              type="number"
              name="takenBatterySoc"
              onChange={onChange}
            />
          </div>
        </Box>
        <ActionButtons
          isActive={isActive}
          onClickNext={onSubmit}
          onClickBack={onClickBack}
          handleReset={handleReset}
          index={index}
          isLoading={isSubmit}
        />
        {errorMsg || resData ? (
          <Typography
            variant="body2"
            sx={{ color: resData ? "green" : "red", mt: 1 }}
          >
            {errorMsg || resData}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default StepFive;
