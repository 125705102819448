import { Box, TextField } from "@mui/material";
import axios from "axios";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import ActionButtons from "../buttons/ActionButtons";
import BillPaid from "./BillPaid";

const inputStyle = { display: "flex", flexDirection: "column", gap: "4px" };

const StepThree = ({ partnerId, onClickBack, index, handleReset }) => {
  const [isBillPaid, setIsBillPaid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    partnerId: "",
    paymentCollectionTimestamp: "",
    amountCollected: "",
  });

  useEffect(() => {
    setFormData((prev) => ({ ...prev, partnerId }));
  }, [partnerId]);
  useEffect(() => {
    if (
      selectedDate &&
      new Date(selectedDate) instanceof Date &&
      !isNaN(selectedDate)
    ) {
      setFormData((prev) => ({
        ...prev,
        // eslint-disable-next-line
        ["paymentCollectionTimestamp"]: selectedDate.toISOString(),
      }));
    }
  }, [selectedDate]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleToBillPay = () => {
    setIsLoading(true);
    axios
      .post(
        `https://moqrjsbmle.execute-api.us-east-1.amazonaws.com/dev/payment/createCashCollectionEntry`,
        formData,
        {
          headers: {
            "x-api-key": "QJhIeh6Q9d2z0iOHuZ3iq3C3v3XERK61aksGmg5e",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.billStatus === "paid") {
          setIsBillPaid(true);
        }
        console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isPositiveInteger =
    /^\d+$/.test(formData.amountCollected) &&
    parseInt(formData.amountCollected, 10) > 0;

  const isActive =
    !partnerId || !isPositiveInteger || !formData.paymentCollectionTimestamp;
  return (
    <Box
      sx={{
        maxWidth: 500,
        "&.MuiFormControl-root": {
          "&.MuiOutlinedInput-root": {
            maxWidth: "40px",
          },
        },
      }}
    >
      {isBillPaid ? (
        <BillPaid handleReset={handleReset} />
      ) : (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <div style={inputStyle}>
              <label>Partner ID</label>
              <TextField size="small" disabled value={partnerId} />
            </div>
            <div style={inputStyle}>
              <label>Cash Collection</label>
              <TextField
                size="small"
                type="number"
                name="amountCollected"
                onChange={handleChange}
              />
            </div>
            <div style={inputStyle}>
              <label>Collection Time</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                    format="DD/MM/YYYY hh:mm A"
                  />
                </DemoItem>
              </LocalizationProvider>
            </div>
          </Box>
          <ActionButtons
            isActive={isActive}
            onClickNext={handleToBillPay}
            onClickBack={onClickBack}
            handleReset={handleReset}
            index={index}
            isLoading={isLoading}
          />
        </Box>
      )}
    </Box>
  );
};

export default StepThree;
