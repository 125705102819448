import { Box, Button, Typography } from "@mui/material";
import Lottie from "lottie-react";
import tickAnimation from "../assets/tick.json";
import { Refresh } from "@mui/icons-material";

const BillPaid = ({ handleReset }) => {
  return (
    <Box sx={{ width: 250 }}>
      <Lottie animationData={tickAnimation} loop={true} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Typography variant="h6">Paid👍</Typography>
        <Button
          variant="contained"
          startIcon={<Refresh />}
          onClick={handleReset}
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
};

export default BillPaid;
