import { Box, Typography } from "@mui/material";

const ProtectedRoute = ({ children, user, feature }) => {
  const userRoles = user["custom:role"].split(/[,\s]+/);
  console.log("userRoles: ", userRoles);
  return userRoles.includes("admin") || userRoles.includes(feature) ? (
    children
  ) : (
    <Typography variant="h3" sx={{ textAlign: "center", mt: 5 }}>
      Not Authorized
    </Typography>
  );
};

export default ProtectedRoute;
