import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const PageNotFound = () => (
  <Box>
    <Box sx={{ mt: 10, textAlign: "center", p: 3 }}>
      <h1>404 - Page Not Found</h1>
      <p>
        Sorry, the page you are looking for does not exist. Please return to our{" "}
        <Link style={{ color: "blue" }} to="/">
          Home
        </Link>
        .
      </p>
    </Box>
  </Box>
);

export default PageNotFound;
